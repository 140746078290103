import React from 'react';
import styles from '../styles/Card.module.css';
import { Card as CardProps } from '../types/Card';
import { useNavigate } from 'react-router-dom';



const Card: React.FC<CardProps> = ({ title, body, created_at, id }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/posts/${id}`);
    };

    return (
        <div className={styles.card} onClick={handleClick}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.body}>
                {body.length > 500 ? `${body.substring(0, 500)}...` : body}
            </p>
            <p className={styles.date}>{new Date(created_at).toLocaleDateString()}</p>
        </div>
    );
};

export default Card;