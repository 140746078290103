import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPost } from '../services/postServices';
import Loading from '../components/Loading';
import styles from '../styles/Post.module.css';
import { BASE_URL } from '../config/api';

import { Post as PostProp } from '../types/Post';

const Post: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [post, setPost] = useState<PostProp | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                if (id) {
                    const data = await getPost(id);
                    setPost(data);
                }
            } catch (error) {
                console.error(`Failed to fetch post with id ${id}:`, error);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [id]);

    if (loading) {
        return <Loading />;
    }

    if (!post) {
        return <div className={styles.error}>Post not found</div>;
    }

    return (
        <div className={styles.container}>
            <div className={styles.post}>
                <h1 className={styles.title}>{post.title}</h1>
                {post.image && <img src={`${BASE_URL}/storage/${post.image}`} alt={post.title} className={styles.image} />}
                <p className={styles.body}>{post.body}</p>
                <p className={styles.date}>Created at: {new Date(post.created_at).toLocaleDateString()}</p>
            </div>
        </div>
    );
};

export default Post;