import React, { useEffect, useState } from 'react';

import { getPosts } from '../services/postServices';
import Card from '../components/Card';
import { Post } from '../types/Post';
import Loading from '../components/Loading';

import styles from '../styles/Posts.module.css';


const Posts: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const data = await getPosts();
                setPosts(data);
            } catch (error) {
                console.error('Failed to fetch posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Blog Posts</h1>
            {posts.map(post => (
                <Card
                    key={post.id}
                    id={post.id}
                    title={post.title}
                    body={post.body}
                    created_at={post.created_at}
                />
            ))}
        </div>
    );
};

export default Posts;