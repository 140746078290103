import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Posts from './pages/Posts'
import Post from './pages/Post'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Posts />} index />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:id" element={<Post />} />
      </Routes>
    </Router>
  )
}

export default App